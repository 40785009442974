var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.item.Value),function(galleryItem,index){return _c('v-card',{key:index,staticClass:"mb-2",attrs:{"elevation":"0","max-width":"700"}},[_c('v-card-text',{staticClass:"px-0"},[_c('v-img',{attrs:{"contain":"","max-width":"700","src":_vm.serverUrl +
          '/images/' +
          _vm.websiteId +
          '/' +
          galleryItem.Name +
          '?token=' +
          _vm.userToken}},[((!_vm.item.ReadOnly && !_vm.needsDisable) || _vm.editItemAdmin)?_c('v-app-bar',{attrs:{"flat":"","dense":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-spacer'),(_vm.item.AddAltTags || _vm.item.AddAltTags == null)?_c('v-btn',{attrs:{"depressed":"","small":"","color":"primaryorange","icon":""},on:{"click":function($event){return _vm.$set(_vm.hovergallery, index, !_vm.hovergallery[index])}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),_c('v-btn',{attrs:{"depressed":"","small":"","color":"primaryred","icon":""},on:{"click":function($event){return _vm.deleteFile(_vm.item.Value, index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1),_c('v-expand-transition',[(_vm.hovergallery[index])?_c('v-layout',{attrs:{"row":"","wrap":""}},[((!_vm.item.ReadOnly && !_vm.needsDisable) || _vm.editItemAdmin)?[(_vm.item.AddAltTags || _vm.item.AddAltTags == null)?_c('v-layout',{attrs:{"align-center":""}},[(_vm.item.Translate || _vm.item.Translate == undefined)?_c('v-select',{staticClass:"altSelect-lang mt-2",attrs:{"items":_vm.itemLanguages,"single-line":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.itemDefaultLanguage),callback:function ($$v) {_vm.itemDefaultLanguage=$$v},expression:"itemDefaultLanguage"}}):_vm._e(),_vm._l((galleryItem.Alt),function(translatedValue,translatedValueIndex){return [(_vm.itemDefaultLanguage == translatedValueIndex)?_c('v-text-field',{key:translatedValueIndex,class:_vm.item.Translate || _vm.item.Translate == undefined
                      ? 'ml-4 mt-2'
                      : 'mt-2',attrs:{"placeholder":"Alt tag","outlined":"","dense":"","hide-details":"auto"},model:{value:(galleryItem.Alt[translatedValueIndex]),callback:function ($$v) {_vm.$set(galleryItem.Alt, translatedValueIndex, $$v)},expression:"galleryItem.Alt[translatedValueIndex]"}}):_vm._e()]})],2):_vm._e()]:[_vm._v(" "+_vm._s(galleryItem.Alt[_vm.currentLanguage])+" ")]],2):_vm._e()],1)],1)],1)}),(!_vm.uploading)?_c('div',[_vm._l((this.files),function(image,index){return [_c('h3',{key:index},[_c('v-icon',[_vm._v("mdi-image")]),_vm._v(" "+_vm._s(image.name)+" "),_c('v-btn',{attrs:{"depressed":"","color":"primaryred","icon":""},on:{"click":function($event){return _vm.deleteSelectedFile(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]})],2):_c('preloader'),(!_vm.item.ReadOnly || _vm.editItemAdmin)?_c('input',{ref:'galleryFileInput' + _vm.objectName,staticClass:"fileInput",attrs:{"multiple":"","type":"file"},on:{"change":function($event){return _vm.setSelectedImages($event.target.files)}}}):_vm._e(),((!_vm.item.ReadOnly && !_vm.needsDisable) || _vm.editItemAdmin)?_c('v-btn',{staticClass:"ml-0 mt-3 mr-3 edit-page-action-button",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.$refs['galleryFileInput' + _vm.objectName].click()}}},[_vm._v(_vm._s(_vm.$t("Choose images")))]):_vm._e(),((!_vm.item.ReadOnly && !_vm.needsDisable) || _vm.editItemAdmin)?_c('v-btn',{staticClass:"ml-0 mt-3 white--text edit-page-action-button",attrs:{"depressed":"","color":"primaryblue","disabled":!_vm.files || _vm.uploading,"loading":_vm.uploading},on:{"click":function($event){return _vm.submitGalleryFiles(_vm.item)}}},[_vm._v(_vm._s(_vm.$t("Upload"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-cloud-upload")])],1):_vm._e(),(_vm.$store.getters.editMode)?[_c('hr',{staticClass:"mt-5"}),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":_vm.item.colWidth < 7
            ? _vm.item.colWidth < 5
              ? 12
              : 4
            : _vm.item.colWidth < 10
            ? 4
            : 2}},[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","label":_vm.$t('Column width'),"outlined":"","dense":""},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Translate item')},model:{value:(_vm.item.Translate),callback:function ($$v) {_vm.$set(_vm.item, "Translate", $$v)},expression:"item.Translate"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Add alt tags')},model:{value:(_vm.item.AddAltTags),callback:function ($$v) {_vm.$set(_vm.item, "AddAltTags", $$v)},expression:"item.AddAltTags"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Read-only')},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Remove frame')},model:{value:(_vm.item.RemoveCard),callback:function ($$v) {_vm.$set(_vm.item, "RemoveCard", $$v)},expression:"item.RemoveCard"}})],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }